const WordList: string[] = [
  'Bordstein',
  'Programmieren',
  'Territorium',
  'Wanduhr',
  'Haushaltskasse',
  'Batman',
  'Kinofilm',
  'Baumhaus',
  'Autobahn',
  'Krankenhaus',
  'Horrorfilm',
  'Aktiengesellschaft',
  'Kleiderschrank',
  'Bankraub',
  'Geldinstitut',
  'Mahlzeit',
  'Fehlerkorrektur',
  'Feedback',
  'Gitarrenspieler',
  'Schlagzeug',
  'Kaffeetasse',
  'Kaffeebecher',
  'Wellenbrecher',
  'Rockkonzert',
  'Videokonferenz',
  'Blumenbeet',
  'Sticker',
  'Aufkleber',
  'Plastikgehäuse',
  'Hollywood',
  'Weltreise',
];

export default WordList;
